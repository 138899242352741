import React from "react"

export default function Terms() {
  return (
    <article className="py-12 mx-auto prose lg:prose-xl">
      <h1>NEWA Data Use Policy</h1>
      <h2>How your personal information is used by NEWA</h2>
      <p>
        Basic personal information is required to create a NEWA account,
        including your name, city, state and e-mail address. NEWA does not
        share, sell, trade, or rent this personal information to others. NEWA
        will only use this information to communicate directly with you about
        your own profile account or to send important website and model alerts.
        NEWA may release account information when it is believed, in good faith,
        that such release is reasonably necessary to (1) comply with local,
        state, or federal law or (2) protect the rights, property or safety of
        Cornell University, our NEWA users, or others. You consent to the
        collection of this information when your NEWA profile account is
        created.
      </p>
      <p>
        You can modify your personal information at any time from your NEWA
        profile settings page. You may erase all of your personal information at
        any time by deleting your NEWA profile account.
      </p>

      <p>
        For best results, NEWA models also require manual data entry collected
        by you, the registered user, about your farm operation. Examples
        include, but are not limited to, apple first blossom open date and
        insect first trap catch dates. These data points are securely stored in
        your NEWA profile account. NEWA only uses these data to improve pest and
        disease forecasts for your direct benefit and will never sell, trade, or
        rent this information to other people or companies.
      </p>

      <h2>Cookies</h2>
      <p>
        If you create a NEWA account and login to{" "}
        <a
          href="http://newa.cornell.edu"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://newa.cornell.edu
        </a>
        , we will use cookies to save your login information and your screen
        display choices. These cookies are used for convenience only.
      </p>

      <h2>Passively Collected Information</h2>
      <p>
        When a user visits the NEWA website, some information such as the
        visitor’s Internet Protocol (IP) address, Internet service provider,
        operating system, the site from which the visitor arrived, and the time
        and date of the user’s visit may be collected automatically. This
        information contains no personally identifiable information. NEWA uses
        Google Analytics, a web metrics service, to collect this type of
        information automatically upon a user’s visit. For more information
        about this service, please review Google’s privacy policy at{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy
        </a>
      </p>

      <h2>Security</h2>
      <p>
        All information provided to NEWA is transmitted using SSL (Secure Socket
        Layer) encryption. SSL is a proven coding system that allows your
        browser to automatically encrypt, or scramble, data before you send it
        to us. We also protect account information by placing it on a secure
        portion of our website.
      </p>

      <h2>Changes to this Policy</h2>
      <p>
        NEWA reserves the right to change this Privacy Policy without advance
        notice. In the event of a policy change, NEWA will post the modified
        policy at this location on the NEWA website, and indicate the date of
        modification.
      </p>

      <div className="mt-4 mb-2">Date last modified</div>
      <div>25 March 2020</div>
    </article>
  )
}
